// components/LeavesApprovalsTable.tsx
import {
  Button,
  Table,
  CellFormatOptions,
  ColumnTable,
} from "@octano/global-ui";
import { CellFormat } from "components/containers/CellFormat";
import { ErrorTableData } from "components/ErrorTableData";
import dayjs from "dayjs";
import { useTableData } from "hooks";
import { usePagination } from "hooks/usePagination";
import LeaveStateApproved from "./LeaveStateApproved";
import { useEffect, useState } from "react";
import { LeaveApprovalModal } from "./LeaveApprovalsModal";
import { LeaveApprovalRequest, LeaveApprovals } from "../types";
import { useLeaveFilters } from "../hooks/useLeaveFilters";
import { LeaveFilters } from "./LeaveFilters";

export interface LeaveApprovalsTableProps {
  data: LeaveApprovals[];
  onRefresh: () => void;
  handleLeaveRequest?: (
    leaveApprovalId: string,
    payload: { approved: boolean; comment?: string }
  ) => Promise<any>;
}

function capitalize(string: string) {
  if (!string) return "";
  return string
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

export default function LeavesApprovalsTable({
  data: dataProp,
  onRefresh,
  handleLeaveRequest,
}: LeaveApprovalsTableProps) {
  const columnsDesktop: ColumnTable[] = [
    {
      columnName: "createdAt",
      headerText: <CellFormat>Fecha de solicitud</CellFormat>,
      cellFormat: ({ value }) => (
        <CellFormat>{dayjs(value).format("DD/MM/YYYY")}</CellFormat>
      ),
    },
    {
      columnName: "requestType",
      headerText: <CellFormat>Tipo de solicitud</CellFormat>,
      cellFormat: ({ value }) => <CellFormat>{capitalize(value)}</CellFormat>,
    },
    {
      columnName: "requestedDays",
      headerText: <CellFormat>Total de días hábiles</CellFormat>,
      cellFormat: ({ value, row }) => (
        <CellFormat>{row.isFullDay ? value : 0.5}</CellFormat>
      ),
    },
    {
      columnName: "comment",
      headerText: <CellFormat>Motivo</CellFormat>,
      cellFormat: ({ value }) => <CellFormat>{value}</CellFormat>,
    },
    {
      columnName: "requestBy",
      headerText: <CellFormat>Solicitado por</CellFormat>,
      cellFormat: ({ value }) => <CellFormat>{capitalize(value)}</CellFormat>,
    },
    {
      columnName: "approved",
      headerText: <CellFormat>Estado de Solicitud</CellFormat>,
      cellFormat: ({ value }) => {
        return (
          <CellFormat>
            <LeaveStateApproved approved={value} />
          </CellFormat>
        );
      },
    },
    {
      columnName: "doneBy",
      headerText: <CellFormat>Resuelto por</CellFormat>,
      cellFormat: ({ value }) => <CellFormat>{capitalize(value)}</CellFormat>,
    },
    {
      columnName: "id",
      headerText: <CellFormat>Acción</CellFormat>,
      width: "40px",
      tdClassName: "pr-2",
      cellFormat: (options: CellFormatOptions) => (
        <Button
          text="REVISAR"
          size="sm"
          className="vacations-information-btn pr-5 pl-5"
          onClick={() => {
            setRecordToEdit(options.row);
            setShowModal(true);
          }}
        />
      ),
    },
  ];

  const { columns } = useTableData({
    columnsTable: columnsDesktop,
    columnsMobile: columnsDesktop,
    columnsDesktop,
  });

  const { filters, handleFilterChange, filteredData, availableYears } =
    useLeaveFilters(dataProp);
  const pagination = usePagination({
    totalItems: filteredData.length,
    limit: 5,
  });
  const [showModal, setShowModal] = useState<boolean>(false);
  const [recordToEdit, setRecordToEdit] = useState<LeaveApprovalRequest | null>(
    null
  );

  const data = filteredData.slice(
    pagination.offset,
    pagination.offset + pagination.itemsPerPage
  );

  const toggleModal = () => {
    setShowModal(!showModal);
    setRecordToEdit(null);
  };

  useEffect(() => {
    pagination.onChangePage(1);
  }, [filteredData]);

  return (
    <>
      <LeaveFilters
        filters={filters}
        onFilterChange={handleFilterChange}
        availableYears={availableYears}
      />
      <Table
        columns={columns}
        noResultsText={<ErrorTableData />}
        data={data}
        striped={false}
        pagination={pagination}
      />
      <LeaveApprovalModal
        show={showModal}
        toggleModal={toggleModal}
        onRefresh={onRefresh}
        recordToEdit={recordToEdit}
        handleLeaveRequest={handleLeaveRequest}
      />
    </>
  );
}
